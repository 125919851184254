
import {defineComponent, onMounted, ref} from "vue";
import moment from "moment";
import WorkService from "@/core/services/WorkService";
import TaskCompleteStatus from "@/views/task/TaskCompleteStatus.vue";
import {GetSpaceId, LoadPanel, TeamSelect} from "@/core/composite/composite";
import {formatDate} from "@/core/helpers/functions";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import UserListSelect from "@/components/common/UserListSelect.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "List",
  components: {
    BaseSelect,
    UserListSelect,
    TaskCompleteStatus,
  },
  setup() {
    const filter = ref({assignedId: '', teamId: ''})
    const spaceId = GetSpaceId().spaceId;
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Weekly Calendar",
        [
          {link: true, router: `/`+spaceId.value+ "/work/all", text: 'Work'},
          {link: false, router: '', text: 'Weekly Calendar'}
        ]
      )
    })
    const loading = ref(false);
    const startOfWeek = moment().startOf('isoWeek').toDate();
    const endOfWeek = moment().endOf('isoWeek').toDate();
    const data = ref<any>([]);
    const loadData = (start, end) => {
      loading.value = true
      WorkService.getWeekly(start, end, filter.value).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    const start = ref(startOfWeek);
    const end = ref(endOfWeek);
    loadData(startOfWeek.getTime(), endOfWeek.getTime())
    return {
      loading,
      start,
      end,
      endOfWeek,
      startOfWeek,
      formatDate,
      data,
      ...LoadPanel(),
      loadData,
      filter,
      ...GetSpaceId(),
      ...TeamSelect(),
    }
  },
  methods: {
    nextWeek() {
      const st = moment(this.start.getTime())
      const end = moment(this.end.getTime());
      this.start = st.clone().add(7, 'day').toDate()
      this.end = end.clone().add(7, 'day').toDate()
      this.loadData(this.start.getTime(), this.end.getTime())
    },
    previousWeek() {
      const st = moment(this.start.getTime())
      const end = moment(this.end.getTime());
      this.start = st.clone().add(-7, 'day').toDate()
      this.end = end.clone().add(-7, 'day').toDate()
      this.loadData(this.start.getTime(), this.end.getTime())
    },
    buildHeader(start: any) {
      const st = moment(start)
      let array: any = []
      array.push(start);
      array.push(st.clone().add(1, "day").toDate())
      array.push(st.clone().add(2, "day").toDate())
      array.push(st.clone().add(3, "day").toDate())
      array.push(st.clone().add(4, "day").toDate())
      array.push(st.clone().add(5, "day").toDate())
      array.push(st.clone().add(6, "day").toDate())
      return array;
    },

    findTask(date: Date, tasks: any[]) {
      return tasks.find((item) => {
        return moment(date).isSame(new Date(item.dueDate), 'day')
      })
    },

    findSchedule(date, scheduleDate) {
      return moment(date).isSame(new Date(scheduleDate), 'day')
    }

  }
})
